<template>
  <div>
    <NuxtLayout v-if="isInitialized">
      <NuxtPage />
      <NuxtRouteAnnouncer />
    </NuxtLayout>
  </div>
</template>

<script setup>
import { useAbility } from '~/composables/useAbility';
import { useAuthStore } from '~/stores/auth';
import { onMounted, watch, ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const { updateAbility } = useAbility();
const authStore = useAuthStore();
const isInitialized = ref(false);

// Função para inicializar a aplicação
const initializeApp = async () => {
  try {
    await authStore.init();
    updateAbility();
    
    // Verifica se precisa redirecionar para login
    const currentRoute = router.currentRoute.value;
    const publicRoutes = ['/login', '/register', '/students'];
    
    if (!authStore.isAuthenticated && 
        !publicRoutes.includes(currentRoute.path) && 
        !currentRoute.path.startsWith('/student/')) {
      router.push('/login');
    }
  } catch (error) {
    console.error('Error initializing app:', error);
    // Em caso de erro, redireciona para login
    if (router.currentRoute.value.path !== '/login') {
      router.push('/login');
    }
  } finally {
    isInitialized.value = true;
  }
};

// Inicializa a aplicação quando montada
onMounted(() => {
  initializeApp();
});

// Observa mudanças no usuário
watch(() => authStore.user, (newValue) => {
  updateAbility();
  console.log('Estado de autenticação mudou:', newValue);

  // Se o usuário for deslogado, redireciona para login
  if (!newValue && router.currentRoute.value.path !== '/login') {
    router.push('/login');
  }
}, { deep: true });

// Observa erros de autenticação
watch(() => authStore.error, (newError) => {
  if (newError) {
    console.error('Authentication error:', newError);
    if (router.currentRoute.value.path !== '/login') {
      router.push('/login');
    }
  }
}, { immediate: true });

// Opcional: Limpa o estado quando o componente é desmontado
onUnmounted(() => {
  isInitialized.value = false;
});
</script>

<style scoped>
/* Opcional: Estilo para o loader */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
</style>