// composables/useAbility.ts
import { ref, watch, onMounted } from "vue";
import { AbilityBuilder, PureAbility } from "@casl/ability";
import { useAuthStore } from "~/stores/auth";

let abilityInstance: PureAbility | null = null;

export function useAbility() {
  const authStore = useAuthStore();

  const defineAbilitiesFor = (user: any) => {
    const { can, build } = new AbilityBuilder(PureAbility);

    if (user && user.roles?.some((role: any) => role.name === "admin")) {
      can("manage", "all");
      console.log("Usuário é admin, todas as permissões concedidas");
    } else if (user && user.all_permissions) {
      user.all_permissions.forEach((permission: string) => {
        const [action, subject] = permission.split(" ");

        // Concede a permissão original
        can(action, subject);

        // Se a permissão for 'see' ou 'view', também concede 'read'
        if (action === "see" || action === "view") {
          can("read", subject);
        }

        // Se a permissão for 'manage', concede todas as ações básicas
        if (action === "manage") {
          can(["read", "create", "update", "delete", "view", "see"], subject);
        }

        console.log(`Permissão concedida: ${action} ${subject}`);
      });
    } else {
      // Permissões padrão para usuários não autenticados ou sem permissões
      can("read", "public");
      console.log("Permissões padrão concedidas");
    }

    return build();
  };

  const updateAbility = () => {
    console.log("Atualizando habilidades");
    abilityInstance = defineAbilitiesFor(authStore.user);
    console.log("Habilidades atualizadas:", abilityInstance.rules);
  };

  // Inicializa as habilidades
  onMounted(() => {
    updateAbility();
    console.log("Habilidades inicializadas");
  });

  // Observa mudanças no usuário e atualiza as habilidades
  watch(
    () => authStore.user,
    (newUser) => {
      console.log("Usuário mudou, atualizando habilidades");
      updateAbility();
    },
    { deep: true }
  );

  const can = (actions: string | string[], subject: string): boolean => {
    if (!abilityInstance) {
      console.warn("Instância de habilidade não encontrada, atualizando");
      updateAbility();
    }

    // Se actions for um array, verifica se alguma das ações é permitida
    if (Array.isArray(actions)) {
      const hasPermission = actions.some((action) =>
        abilityInstance?.can(action, subject)
      );
      console.log(
        `Verificação de múltiplas permissões: [${actions.join(
          ", "
        )}] ${subject} = ${hasPermission}`
      );
      return hasPermission;
    }

    // Se for uma única action, verifica normalmente
    const result = abilityInstance
      ? abilityInstance.can(actions, subject)
      : false;
    console.log(`Verificação de permissão: ${actions} ${subject} = ${result}`);
    return result;
  };

  return {
    can,
    updateAbility,
  };
}
