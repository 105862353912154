// plugins/fetch.ts
export default defineNuxtPlugin((_nuxtApp) => {
    globalThis.$fetch = $fetch.create({
      onRequest({ options }) {
        const config = useRuntimeConfig()
        
        // Define a base URL
        options.baseURL = config.public.apiBase
  
        // Configurações CORS
        options.credentials = 'include'
  
        // Headers padrão
        options.headers = {
          ...options.headers,
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        };
  
        // Adiciona token se existir
        if (process.client) {
          const token = localStorage.getItem('token')
          if (token) {
            options.headers.Authorization = `Bearer ${token}`
          }
        }
        // Adiciona token se existir
        if (process.client) {
          const token = localStorage.getItem('token')
          if (token) {
            options.headers.Authorization = `Bearer ${token}`
          }
        }
      },
  
      onResponseError({ response }) {
                    console.error(
                      "Response error:",
                      response.status,
                      response._data
                    );

        if (response.status === 401) {
          const authStore = useAuthStore()
          authStore.clearAuthData()
          navigateTo('/login')
        }
      }
    })
  })