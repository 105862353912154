// stores/auth.ts
import { defineStore } from 'pinia'

interface User {
  id: number
  name: string
  email: string
  [key: string]: any
}

interface AuthState {
  user: User | null
  token: string | null
  loading: boolean
  error: string | null
}

export const useAuthStore = defineStore('auth', {
  state: (): AuthState => ({
    user: null,
    token: null,
    loading: false,
    error: null
  }),

  getters: {
    isAuthenticated: (state) => !!state.token
  },

  actions: {
    async init() {
      const token = localStorage.getItem('token')
      if (token) {
        this.token = token
        await this.fetchUser()
      }
    },

    async login(email: string, password: string) {
      this.loading = true
      this.error = null

      try {
        const response = await $fetch("/api/auth/login", {
          method: "POST",
          body: { email, password },
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Origin: "https://lojasforimage.netlify.app",
          },
          credentials: "include",
        });

        console.log("Login response:", response);

    
        if (response.access_token) {
          this.token = response.access_token
          localStorage.setItem('token', response.access_token)
          await this.fetchUser()
          return true
        }
    
        this.error = 'Credenciais inválidas'
        return false
      } catch (error: any) {
        console.error('Login error:', error)
        this.error = error?.data?.message || 'Erro ao realizar login'
        return false
      } finally {
        this.loading = false
      }
    },

    async fetchUser() {
      if (!this.token) return null

      try {
        const response = await $fetch('/api/auth/user', {
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        })

        this.user = response.data?.user || null
        return this.user
      } catch (error) {
        this.clearAuthData()
        throw error
      }
    },

    async logout() {
      if (this.token) {
        try {
          await $fetch('/api/auth/logout', {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          })
        } catch (error) {
          console.error('Logout error:', error)
        }
      }
      
      this.clearAuthData()
      navigateTo('/login')
    },

    clearAuthData() {
      this.user = null
      this.token = null
      this.error = null
      localStorage.removeItem('token')
      localStorage.removeItem('rememberMe')
    },

    async checkStudent(document: string): Promise<StudentCheckResponse | null> {
      this.loading = true
      this.error = null

      try {
        const response = await $fetch<StudentCheckResponse>('/api/student/validate', {
          method: 'POST',
          body: { document },
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        })

        if (response) {
          console.log('Student check response:', response)
          return {
            success: response.success,
            message: response.message
          }
        }

        return null
      } catch (error: any) {
        console.error('Error checking student:', error)
        this.error = error?.data?.message || 'Erro ao verificar estudante'
        return {
          success: false,
          message: this.error
        }
      } finally {
        this.loading = false
      }
    }}

})