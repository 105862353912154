// plugins/casl.ts
import { useAbility } from "~/composables/useAbility";
import { useAuthStore } from "~/stores/auth"; // Ajuste o caminho conforme necessário

export default defineNuxtPlugin((nuxtApp) => {
  const { can, updateAbility } = useAbility();
  const authStore = useAuthStore();

  // Atualiza as habilidades quando o plugin é inicializado
  updateAbility();

  // Atualiza as habilidades quando o estado de autenticação muda
  nuxtApp.hook("app:created", () => {
    watch(
      () => authStore.user,
      () => {
        console.log("Estado de autenticação mudou, atualizando habilidades");
        updateAbility();
      },
      { deep: true }
    );
  });

  // Fornece 'can' e 'updateAbility' de forma consistente
  nuxtApp.provide("ability", {
    can,
    updateAbility,
  });

  return {
    provide: {
      can,
      updateAbility,
    },
  };
});
