// plugins/api.ts
import { defineNuxtPlugin } from '#app'
import { useAuthStore } from '~/stores/auth'

export default defineNuxtPlugin((nuxtApp) => {
  const authStore = useAuthStore()
  const config = useRuntimeConfig()

  globalThis.$fetch = $fetch.create({
    baseURL: config.public.apiBase,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    async onRequest({ request, options }) {
      const token = authStore.token
      if (token) {
        options.headers = {
          ...options.headers,
          Authorization: `Bearer ${token}`
        }
      }
    },
    async onResponseError({ response }) {
      if (response.status === 401) {
        // Token expirado ou inválido
        try {
          const success = await authStore.refreshToken()
          if (!success) {
            authStore.clearAuthData()
            navigateTo('/login')
          }
        } catch {
          authStore.clearAuthData()
          navigateTo('/login')
        }
      }
    }
  })
})