// plugins/primevue.ts
import { defineNuxtPlugin } from "#app";
import PrimeVue from "primevue/config";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Card from "primevue/card";
import Password from "primevue/password";
import Checkbox from "primevue/checkbox";

// Importando os estilos
import "~/assets/theme/themes/aura/aura-light/purple/theme.scss";
import "primevue/resources/primevue.css";
import "primeicons/primeicons.css";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(PrimeVue, { ripple: true });
  nuxtApp.vueApp.component("Button", Button);
  nuxtApp.vueApp.component("InputText", InputText);
  nuxtApp.vueApp.component("Card", Card);
  nuxtApp.vueApp.component("Password", Password);
  nuxtApp.vueApp.component("Checkbox", Checkbox);
});
