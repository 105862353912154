// plugins/auth.ts
import { defineNuxtPlugin } from '#app'
import { useAuthStore } from '~/stores/auth'

const publicRoutes = [
  'login',
  'register',
  'forgot-password',
  'students'
]

const publicPaths = [
  '/login',
  '/register',
  '/forgot-password',
  '/students'
]
export default defineNuxtPlugin(async (nuxtApp) => {
  const authStore = useAuthStore()
  const router = useRouter()


  const isPublicRoute = (route: any): boolean => {
    // Verifica pelo nome da rota
    if (publicRoutes.includes(route.name as string)) return true
    
    // Verifica pelo caminho
    if (publicPaths.includes(route.path)) return true
    
    // Verifica padrões de URL
    return route.path.startsWith('/student/validate') || 
           route.path.startsWith('/validate-student')
  }

  // Função para verificar token expirado
  const isTokenExpired = (token: string): boolean => {
    try {
      const payload = JSON.parse(atob(token.split('.')[1]))
      const expTime = payload.exp * 1000 // Converter para milissegundos
      return Date.now() >= expTime
    } catch {
      return true
    }
  }

  // Inicialização do estado de autenticação
  if (process.client) {
    const token = localStorage.getItem('token')
    if (token) {
      // Verifica se o token está expirado
      if (isTokenExpired(token)) {
        authStore.clearAuthData()
        router.push('/login')
      } else {
        authStore.token = token
        try {
          await authStore.fetchUser()
        } catch (error) {
          console.error('Error fetching user during init:', error)
          authStore.clearAuthData()
          if (!isPublicRoute(router.currentRoute.value)) {
            router.push('/login')
          }
        }
      }
    }
  }

  // Interceptador de navegação
  nuxtApp.hook('page:start', async () => {
    if (process.client) {
      const token = authStore.token
      const currentRoute = router.currentRoute.value
      
      // Se for uma rota pública, permite o acesso
      if (isPublicRoute(currentRoute)) {
        return
      }

      if (token) {
        // Verifica expiração do token durante a navegação
        if (isTokenExpired(token)) {
          console.log('Token expired during navigation')
          authStore.clearAuthData()
          router.push('/login')
          return
        }

        // Atualiza dados do usuário se necessário
        if (!authStore.user) {
          try {
            await authStore.fetchUser()
          } catch (error) {
            console.error('Error fetching user during navigation:', error)
            authStore.clearAuthData()
            router.push('/login')
          }
        }
      }
    }
  })

  // Interceptador de erros
  nuxtApp.hook('vue:error', (error) => {
    console.error('Vue error:', error)
    // Aqui você pode adicionar lógica para tratamento de erros,
    // como enviar para um serviço de monitoramento
  })

  // Interceptador para verificar autenticação em rotas protegidas
  nuxtApp.hook('page:finish', () => {
    const currentRoute = router.currentRoute.value
    if (isPublicRoute(currentRoute)) {
      return
    }
    const requiresAuth = !['login', 'register', 'forgot-password']
      .includes(currentRoute.name as string)

    if (requiresAuth && !authStore.isAuthenticated) {
      router.push('/login')
    }
  })

  // Adiciona helpers globais
  return {
    provide: {
      auth: {
        isAuthenticated: () => authStore.isAuthenticated,
        hasPermission: (permission: string) => authStore.hasPermission?.(permission) ?? false,
        hasRole: (role: string) => authStore.hasRole?.(role) ?? false,
        user: () => authStore.user,
        isPublicRoute: (route: any) => isPublicRoute(route)
      }
    }
  }
})