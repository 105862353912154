// plugins/csrf.ts
export default defineNuxtPlugin(async () => {
  const config = useRuntimeConfig();

  const getCsrfToken = async () => {
    try {
      await $fetch(`${config.public.apiBase}/sanctum/csrf-cookie`, {
        credentials: "include",
        headers: {
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      });
    } catch (error) {
      console.error("Error fetching CSRF token:", error);
    }
  };

  return {
    provide: {
      getCsrfToken,
    },
  };
});
